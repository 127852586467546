import trans from '../../helpers/trans';
import { ScheduleRoutes } from '../helpers';
import { SubNavRoute } from '../routes';

export type ShiftNavigationRouteKey =
    'calendar' |
    'list-view' |
    'availability' |
    'hours' |
    'base-schedules' |
    'special-days' |
    'district-division' |
    'exchange-requests';

const shiftNavigationRoutes: SubNavRoute[] = [
    {
        key: 'calendar',
        path: ScheduleRoutes.calendar(),
        label: trans('routes.shifts.calendar.label'),
        permissions: ['view-all-shifts'],
    },
    {
        key: 'list-view',
        path: ScheduleRoutes.listView(),
        label: trans('routes.shifts.listView.label'),
        permissions: ['view-all-shifts'],
    },
    {
        key: 'exchange-requests',
        hasSomePermission: true,
        path: ScheduleRoutes.exchangeRequests(),
        label: trans('routes.shifts.exchangeRequests.label'),
        permissions: ['view-all-exchanges', 'view-all-shift-exchanges'],
    },
    {
        key: 'hours',
        path: ScheduleRoutes.hours(),
        label: trans('routes.shifts.hours.label'),
        permissions: ['view-all-user-profiles', 'view-all-absences', 'view-all-leave-of-absences'],
    },
    {
        key: 'base-schedules',
        path: ScheduleRoutes.baseSchedules(),
        label: trans('routes.shifts.baseSchedules.label'),
        permissions: ['view-all-base-schedules'],
    },
    {
        key: 'special-days',
        path: ScheduleRoutes.specialDays(),
        label: trans('routes.shifts.specialDays.label'),
        permissions: ['view-all-special-days'],
    },
    {
        key: 'district-division',
        path: ScheduleRoutes.districtDivision(),
        label: trans('routes.shifts.districtDivision.label'),
        permissions: [
            'view-all-district-divisions',
            'view-all-district-division-groups',
            'view-all-district-division-districts',
            'view-all-district-division-district-plannings',
        ],
    },
    {
        key: 'availability',
        path: ScheduleRoutes.availability(),
        label: trans('routes.shifts.availability.label'),
        permissions: [
            'view-all-absences',
            'view-all-leave-of-absences',
            'view-all-prefer-to-work-time-slots',
            'view-unavailable-to-work-time-slots',
        ],
    },
];

export default shiftNavigationRoutes;
