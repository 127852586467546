import { FC, ReactElement, useEffect } from 'react';

import {
    addDays,
    addMinutes,
    addYears,
    differenceInDays,
    endOfDay,
    startOfDay,
    startOfToday,
} from 'date-fns';

import { SpecialDaysList } from '../../containers';
import { fetchSpecialDays } from '../../redux/specialDays/specialDaysActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedSpecialDaysListProps {
    startDate: Date;
    endDate: Date;
    className?: string;
}

export const ConnectedSpecialDaysList: FC<ConnectedSpecialDaysListProps> = ({
    startDate,
    endDate,
    className = '',
}): ReactElement | null => {
    const dispatch = useTypedDispatch();
    const { specialDays, isLoading } = useTypedSelector(state => state.pacoSpecialDaysReducer);

    const multipleDays = differenceInDays(endDate, startDate) > 0;

    const startDateNormalized = startOfDay(startDate);
    const endDateNormalized = addDays(endOfDay(endDate), -1);

    let firstDateToCheckSpecialDaysFor = startOfToday();
    if (startDate < firstDateToCheckSpecialDaysFor) {
        firstDateToCheckSpecialDaysFor = startDateNormalized;
    }

    let latestDateToCheckSpecialDaysFor = addYears(startOfToday(), 2);
    if (endDate > latestDateToCheckSpecialDaysFor) {
        latestDateToCheckSpecialDaysFor = endDate;
    }

    useEffect(() => {
        dispatch(fetchSpecialDays(
            addMinutes(firstDateToCheckSpecialDaysFor, -1),
            endOfDay(latestDateToCheckSpecialDaysFor),
        ));
    }, [firstDateToCheckSpecialDaysFor, latestDateToCheckSpecialDaysFor]);

    const specialDaysWithinRange = specialDays
        .filter(specialDay => specialDay.date >= startDateNormalized && specialDay.date <= endDateNormalized);

    if (!specialDaysWithinRange.length || isLoading) {
        return null;
    }

    return (
        <SpecialDaysList
            specialDays={specialDaysWithinRange}
            multipleDays={multipleDays}
            className={className}
        />
    );
};
