import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { LeaveOfAbsencesOverviewPage } from '../../@paco/pages';
import { getPacoPayrollPeriods } from '../../@paco/redux/payrollPeriods/payrollPeriodsActions';
import { getPayrollPeriods } from '../../redux/app/appActions';
import { getEmployees } from '../../redux/users/usersActions';
import AbsencesList from './AbsencesList';
import AbsencesOverview from './AbsencesOverview';
import { AbsencesRoutes } from './AbsencesRoutes';
import LeaveOfAbsenceRequests from './LeaveOfAbsenceRequests';
import LeaveOfAbsencesCalendar from './LeaveOfAbsencesCalendar';

import './Absences.scss';


class LegacyAbsencesRouteGroup extends Component {
    componentDidMount() {
        this.props.dispatch(getEmployees());
        this.props.dispatch(getPayrollPeriods());
        this.props.dispatch(getPacoPayrollPeriods());
    }

    render() {
        return (
            <Routes>
                <Route
                    path={AbsencesRoutes.leaveOfAbsenceRequests()}
                    element={<LeaveOfAbsenceRequests {...this.props} />}
                />
                <Route
                    path={AbsencesRoutes.leaveOfAbsencesCalendar()}
                    element={<LeaveOfAbsencesCalendar {...this.props} />}
                />
                <Route
                    path={AbsencesRoutes.leaveOfAbsencesOverview()}
                    element={<LeaveOfAbsencesOverviewPage {...this.props} />}
                />
                <Route path={AbsencesRoutes.absencesList()} element={<AbsencesList {...this.props} />} />
                <Route
                    path={AbsencesRoutes.absencesOverview()}
                    element={<AbsencesOverview {...this.props} />}
                />
            </Routes>
        );
    }
}

LegacyAbsencesRouteGroup.propTypes = {
    dispatch: PropTypes.func.isRequired,
    departments: PropTypes.array.isRequired,
    departmentOptions: PropTypes.array.isRequired,
    payrollPeriods: PropTypes.array.isRequired,
    pacoPayrollPeriods: PropTypes.array.isRequired,
    roles: PropTypes.array.isRequired,
    filter: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    exportCsvRequest: PropTypes.bool.isRequired,
    employees: PropTypes.array.isRequired,
    exchangeRequests: PropTypes.array.isRequired,
    openLeaveOfAbsencesRequests: PropTypes.array.isRequired,
    calendarLeaveOfAbsencesRequests: PropTypes.array.isRequired,
    lastChangedLeaveOfAbsenceId: PropTypes.string.isRequired,
    syncLoketRequest: PropTypes.bool.isRequired,
    absences: PropTypes.array.isRequired,
    timeMode: PropTypes.string.isRequired,
    selectedDays: PropTypes.array.isRequired,
    selectedDate: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    permissions: PropTypes.array.isRequired,
    logs: PropTypes.array,
    logsLoading: PropTypes.bool.isRequired,
    userRoutes: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
    return {
        departments: state.authenticatedUserReducer.departments,
        departmentOptions: state.globalFiltersReducer.departmentOptions,
        payrollPeriods: state.appReducer.payrollPeriods,
        pacoPayrollPeriods: state.pacoPayrollPeriodsReducer.payrollPeriods,
        employmentTypes: state.appReducer.employmentTypes,
        roles: state.appReducer.roles,
        filter: state.filterReducer.filter,
        loading: state.absencesReducer.loading,
        exportCsvRequest: state.absencesReducer.exportCsvRequest,
        pagination: state.paginationReducer,
        employees: state.usersReducer.employees,
        exchangeRequests: state.absencesReducer.exchangeRequests,
        openLeaveOfAbsencesRequests: state.absencesReducer.openLeaveOfAbsencesRequests,
        calendarLeaveOfAbsencesRequests: state.absencesReducer.calendarLeaveOfAbsencesRequests,
        lastChangedLeaveOfAbsenceId: state.absencesReducer.lastChangedLeaveOfAbsenceId,
        syncLoketRequest: state.absencesReducer.syncLoketRequest,
        absences: state.absencesReducer.absences,
        absencesInDateRange: state.absencesReducer.absencesInDateRange,
        timeMode: state.weekNavigatorReducer.mode,
        selectedDays: state.weekNavigatorReducer.selectedDays,
        selectedDate: state.weekNavigatorReducer.selectedDate,
        currentUser: state.authenticatedUserReducer.user,
        permissions: state.authenticatedUserReducer.permissions,
        logs: state.logsReducer.logs,
        logsLoading: state.logsReducer.loading,
        userRoutes: state.authenticatedUserReducer.permittedUserRoutes,
    };
}

export default connect(mapStateToProps)(LegacyAbsencesRouteGroup);
