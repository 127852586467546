import React, { FC, ReactElement } from 'react';

import classnames from 'classnames';

import SpecialDayTooltip from '../../../components/SpecialDayToolTip/SpecialDayTooltip';
import { formatDate } from '../../../helpers/date';
import { SpecialDayViewModel } from '../../../models';
import trans from '../../helpers/trans';

import './SpecialDaysList.scss';

interface SpecialDaysListProps {
    specialDays: SpecialDayViewModel[];
    multipleDays?: boolean;
    className?: string;
}

export const SpecialDaysList: FC<SpecialDaysListProps> = ({
    specialDays,
    multipleDays = false,
    className = '',
}): ReactElement => {
    const listClassName = classnames('special-days-list', {
        'special-days-list--multiple-days': multipleDays,
        'special-days-list--single-day': !multipleDays,
    }, className);

    return (
        <div className={listClassName}>
            <p className="special-days-list__message">
                {
                    multipleDays
                        ? trans('containers.specialDaysList.multipleDaysWarning')
                        : trans('containers.specialDaysList.singleDayWarning')
                }
            </p>
            <ul className="special-days-list__list">
                {specialDays.map(specialDay => (
                    <li key={specialDay.id} className="special-days-list__list-item">
                        <div className="special-days-list__content">
                            <SpecialDayTooltip
                                specialDay={specialDay}
                                isMobile={false}
                            />
                            {
                                multipleDays
                                    ? `${specialDay.description} (${formatDate(specialDay.date, 'd MMMM')})`
                                    : specialDay.description
                            }
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};
