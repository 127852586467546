import React, { useState } from 'react';

import PropTypes from 'prop-types';
import Select from 'react-select';
import {
    Button, Col, CustomInput,
    FormGroup, Label, ModalBody, ModalFooter, ModalHeader, Row,
} from 'reactstrap';

import FormDatePicker from '../../../components/FormDatePicker/FormDatePicker';
import Icon from '../../../components/style/Icon/Icon';
import { checkPermission, getObjProperty } from '../../../helpers';
import { formatDate } from '../../../helpers/date';
import { translate } from '../../../helpers/translations/translator';

import './Forms.scss';

const AddSpecialDay = (props) => {
    const {
        onCancel,
        permissions,
        specialDayTypes,
        specialDay = {},
    } = props;
    const [type, setType] = useState(getObjProperty(specialDay, 'type'));
    const [date, setDate] = useState(getObjProperty(specialDay, 'date') || new Date());
    const [descr, setDescr] = useState(getObjProperty(specialDay, 'description') || '');
    const [holidayAllowance, setHolidayAllowance] = useState(getObjProperty(specialDay, 'holidayAllowance') || false);
    const [isVisibleInEmployeeApp, setIsVisibleInEmployeeApp] = useState(getObjProperty(specialDay, 'isVisibleInEmployeeApp') || false);

    const canEditHolidayAllowance = checkPermission(permissions, 'edit-special-days-holiday-allowance', 'add-special-day');
    const editOrAdd = (specialDay ? translate('common.edit') : translate('common.add'));
    const canSubmit = !!descr.length && type;

    const onSubmit = (e) => {
        e.preventDefault();
        props.onSubmit({
            id: getObjProperty(specialDay, 'id'),
            date: formatDate(date, 'yyyy-MM-dd'),
            type: type || specialDayTypes[0].name,
            ...(canEditHolidayAllowance && { holidayAllowance }),
            isVisibleInEmployeeApp,
            descr,
        });
    };

    const options = specialDayTypes.map((val) => (
        {
            label: <Icon kind={val.name} isImage />,
            value: val.name,
            id: val.name,
        }
    ));

    return (
        <form onSubmit={(e) => { e.preventDefault(); onSubmit(); }}>
            <ModalHeader>{`${translate('pages.specialDays.specialDay')} ${editOrAdd.toLowerCase()}`}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{translate('common.category')}</Label>
                            <Select
                                classNamePrefix="react-select"
                                value={options.find(option => option.id === type)}
                                placeholder={translate('pages.specialDays.selectIcon')}
                                id="type"
                                options={options}
                                onChange={(e) => setType(e.id)}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>{translate('common.date')}</Label>
                            <FormDatePicker
                                selected={date}
                                onChange={(val) => setDate(val)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{translate('common.description')}</Label>
                            <CustomInput
                                className="form-control"
                                type="text"
                                id="descr"
                                name="descr"
                                value={descr}
                                onChange={(e) => setDescr(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                { canEditHolidayAllowance && (
                    <Row>
                        <FormGroup check>
                            <Label check>
                                <CustomInput
                                    checked={holidayAllowance}
                                    label={translate('pages.specialDays.holidayAllowance')}
                                    type="checkbox"
                                    id="holidayAllowance"
                                    onChange={() => setHolidayAllowance(!holidayAllowance)}
                                />
                            </Label>
                        </FormGroup>
                    </Row>
                )}
                <Row>
                    <FormGroup check>
                        <Label check>
                            <CustomInput
                                checked={isVisibleInEmployeeApp}
                                label={translate('pages.specialDays.isVisibleInEmployeeApp')}
                                type="checkbox"
                                id="isVisibleInEmployeeApp"
                                onChange={() => setIsVisibleInEmployeeApp(!isVisibleInEmployeeApp)}
                            />
                        </Label>
                    </FormGroup>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button type="button" color="link" id="modal-close" onClick={onCancel}>{translate('common.cancel')}</Button>
                <Button type="submit" color="orange" disabled={!canSubmit} onClick={onSubmit}>{editOrAdd}</Button>
            </ModalFooter>
        </form>
    );
};

AddSpecialDay.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    specialDay: PropTypes.object,
    specialDayTypes: PropTypes.array.isRequired,
};

export default AddSpecialDay;
