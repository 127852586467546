import React, { FC, useMemo } from 'react';

import classnames from 'classnames';

import { getUsersWithOverlappingShifts } from '../../../../../../@paco/entities/Shift/ShiftHelpers';
import { UnavailableToWorkTimeSlotViewModel } from '../../../../../../entities/UnavailableToWorkTimeSlot/UnavailableToWorkTimeSlot';
import {
    AbsenceViewModel,
    LeaveOfAbsenceViewModel,
    ShiftDraggable,
} from '../../../../../../models';
import ConceptShift from '../ConceptShift/ConceptShift';

import './ShiftsList.scss';


interface ShiftsListProps {
    className?: string;
    shiftClassName?: string;
    shifts: ShiftDraggable[];
    leaveOfAbsences?: LeaveOfAbsenceViewModel[];
    absences?: AbsenceViewModel[];
    unavailableTimeSlots?: UnavailableToWorkTimeSlotViewModel[];
    canEditShifts: boolean;
    canEditShiftConcepts: boolean;
    canEditBaseScheduleShifts: boolean;
    showEmploymentTypeBadge: boolean;
    showEmployees: boolean;
    highlightShiftId?: string;
    onShiftClick: (shift: ShiftDraggable) => void;
    onShiftStartDrag: (shift: ShiftDraggable) => void;
}

const ShiftsList: FC<ShiftsListProps> = ({
    className,
    shiftClassName,
    shifts,
    leaveOfAbsences = [],
    absences = [],
    unavailableTimeSlots = [],
    canEditShifts,
    canEditShiftConcepts,
    canEditBaseScheduleShifts,
    showEmploymentTypeBadge,
    showEmployees,
    highlightShiftId,
    onShiftClick,
    onShiftStartDrag,
}) => {
    const classNames = useMemo(() => classnames('concept-shifts-list', {
        [`${className}__shifts-list`]: className,
    }), [className]);

    const usersWithOverlappingShifts = useMemo(() => getUsersWithOverlappingShifts(shifts), [shifts]);

    return (
        <div className={classNames}>
            {
                shifts.map((shift, i) => {
                    const key = `${shift.id}${shift.dragState === 'preview' ? '-preview' : ''}`;
                    const isHighlighted = shift.dragState !== 'preview' && highlightShiftId === shift.id;
                    const usersWithOverlappingShift = usersWithOverlappingShifts[i];

                    return (
                        <ConceptShift
                            key={key}
                            className={shiftClassName}
                            shift={shift}
                            leaveOfAbsences={leaveOfAbsences}
                            absences={absences}
                            unavailableTimeSlots={unavailableTimeSlots}
                            usersWithOverlappingShift={usersWithOverlappingShift}
                            isHighlighted={isHighlighted}
                            canEditShifts={canEditShifts}
                            canEditShiftConcepts={canEditShiftConcepts}
                            canEditBaseScheduleShifts={canEditBaseScheduleShifts}
                            showEmploymentTypeBadge={showEmploymentTypeBadge}
                            showEmployees={showEmployees}
                            onShiftClick={onShiftClick}
                            onShiftStartDrag={onShiftStartDrag}
                        />
                    );
                })
            }
        </div>
    );
};

export default ShiftsList;
