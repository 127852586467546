import React, { Component } from 'react';

import serialize from 'form-serialize';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
    Button,
    Col,
    FormGroup,
    Input,
    Label,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';

import { LeaveTypeSelectInput } from '../../../@paco/compositions';
import { doesPeriodOverlapWithLockedPayrollPeriod } from '../../../@paco/entities/PayrollPeriod/PayrollPeriodHelpers';
import { transformToPeriod } from '../../../@paco/entities/Period/PeriodTransformers';
import WeekdayHoursInputs from '../../../components/WeekHoursInputs/WeekHoursInputs';
import { USER_ROLE_EMPLOYEE, USER_ROLE_JUNIOR_MANAGER, USER_ROLE_MANAGER } from '../../../constants';
import { checkPermission, getUserFullName } from '../../../helpers';
import { compareAsc, formatDate } from '../../../helpers/date';
import UpToAndIncludingDate from '../../../helpers/date/UpToAndIncludingDate';
import { translate } from '../../../helpers/translations/translator';
import transformWeeksWithHoursToPayrollPeriods from '../../../services/WeekWithHours/transformWeeksWithHoursToPayrollPeriods';
import SelectEmployeeLabel from '../components/SelectEmployeeLabel/SelectEmployeeLabel';
import { SetStartEndDateTimeForm } from './SetStartEndDateTimeForm/SetStartEndDateTimeForm';

import './Forms.scss';


const START_DATE = `${formatDate(new Date(), 'yyyy-MM-dd')}T08:00:00`;
const END_DATE = `${formatDate(new Date(), 'yyyy-MM-dd')}T18:00:00`;
const DEFAULT_LEAVE_TYPE = 'normal';

class AddLeaveOfAbsenceForm extends Component {
    formRef = React.createRef();

    state = {
        startDate: START_DATE,
        endDate: END_DATE,
        payrollPeriodError: false,
        periodsWithHours: [],
        weeksWithHours: [],
        userId: null,
        leaveType: DEFAULT_LEAVE_TYPE,
        canSubmit: false,
        data: null,
    };

    static getDerivedStateFromProps(props, state) {
        const { pacoPayrollPeriods } = props;
        const { userId, startDate, endDate } = state;

        const payrollPeriodError = doesPeriodOverlapWithLockedPayrollPeriod(
            transformToPeriod(new Date(startDate), new Date(endDate)),
            pacoPayrollPeriods,
        );

        const dateError = compareAsc(startDate, endDate) !== -1;

        return {
            ...state,
            payrollPeriodError,
            canSubmit: !!userId && !dateError && !payrollPeriodError,
        };
    }

    componentDidMount() {
        this.onFormChange();
    }

    onFormChange = () => {
        const data = serialize(this.formRef.current, { hash: true });
        this.setState({
            data,
        });
    };

    onFormSubmit = (e) => {
        e.preventDefault();
        const {
            startDate, endDate, userId, periodsWithHours, weeksWithHours, leaveType,
        } = this.state;

        const data = {
            start: startDate,
            end: endDate,
            reason: this.state.data.reason,
        };

        this.props.onSubmit(
            userId,
            data,
            periodsWithHours,
            weeksWithHours,
            this.state.data.comment,
            leaveType,
        );
    };

    onChangeEmployee = (id) => {
        this.setState({
            userId: id,
        });
    };

    onChangeLeaveType = (value) => {
        this.setState({
            leaveType: value,
        });
    };

    onSetStartEndDateTimeFormChange = (start, end) => {
        this.setState({
            startDate: start,
            endDate: end,
        });
    };

    onWeekdayHoursInputsChange = (weeksWithHours) => {
        const periodsWithHours = transformWeeksWithHoursToPayrollPeriods(weeksWithHours, this.props.payrollPeriods);

        this.setState({
            periodsWithHours,
            weeksWithHours,
        });
    };

    getEmployees = () => {
        const { permissions, employees = [] } = this.props;
        const canAddEmployeeLOAs = checkPermission(permissions, 'add-new-employee-leave-of-absences');
        const canAddManagerLOAs = checkPermission(permissions, 'add-new-manager-leave-of-absences');
        const canAddJuniorManagerLOAs = checkPermission(permissions, 'add-new-junior-manager-leave-of-absences');

        if (checkPermission(permissions, 'add-new-leave-of-absences')) {
            return employees;
        }

        return employees.filter(user => (canAddEmployeeLOAs
            && user.roles.find(role => role.slug === USER_ROLE_EMPLOYEE))
            || (canAddManagerLOAs && user.roles.find(role => role.slug === USER_ROLE_MANAGER))
            || (canAddJuniorManagerLOAs && user.roles.find(role => role.slug === USER_ROLE_JUNIOR_MANAGER)));
    };

    render() {
        const {
            onCancel,
            pacoPayrollPeriods,
            payrollPeriods,
            permissions,
        } = this.props;
        const {
            multipleDays,
            canSubmit,
            startDate,
            endDate,
            leaveType,
            weeksWithHours,
            payrollPeriodError,
        } = this.state;
        const employees = this.getEmployees();
        const employeeOptions = employees.map(employee => ({
            label: <SelectEmployeeLabel user={employee} />,
            value: getUserFullName(employee),
            id: employee.id,
        }));
        const canViewAllLeaveTypes = checkPermission(permissions, 'view-all-leave-types', 'add-leave-of-absence-form');

        return (
            <form ref={this.formRef} onSubmit={this.onFormSubmit} onChange={this.onFormChange} className={multipleDays ? 'form-absence--full-day' : ''}>
                <ModalHeader>
                    {translate('pages.absences.addLeaveOfAbsence')}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>{translate('common.employee')}</Label>
                                <Select
                                    id="employee-search"
                                    isDisabled={employeeOptions.length === 0}
                                    options={employeeOptions}
                                    maxMenuHeight={400}
                                    placeholder={translate('pages.shifts.searchEmployee')}
                                    noOptionsMessage={() => translate('pages.shifts.noEmployeeFound')}
                                    onChange={e => this.onChangeEmployee(e.id)}
                                    classNamePrefix="react-select"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <div className="leave-of-absence-form__row">
                        <div className="leave-of-absence-form__col">
                            <LeaveTypeSelectInput
                                canViewAllLeaveTypes={canViewAllLeaveTypes}
                                leaveType={leaveType}
                                onChange={this.onChangeLeaveType}
                            />
                        </div>
                    </div>
                    <SetStartEndDateTimeForm
                        hasError={payrollPeriodError}
                        errorText={payrollPeriodError ? translate('common.datesOverlapWithLockedPayrollPeriod') : undefined}
                        startDate={startDate}
                        endDate={endDate}
                        showSpecialDays
                        onChange={this.onSetStartEndDateTimeFormChange}
                    />
                    <WeekdayHoursInputs
                        disabled={payrollPeriodError}
                        pacoPayrollPeriods={pacoPayrollPeriods}
                        payrollPeriods={payrollPeriods}
                        weekWithHours={weeksWithHours}
                        type="leaveOfAbsences"
                        startDate={startDate ? new Date(startDate) : null}
                        endDate={endDate ? new UpToAndIncludingDate(endDate) : null}
                        onChange={this.onWeekdayHoursInputsChange}
                    />
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>{translate('common.description')}</Label>
                                <Input
                                    type="text"
                                    className="form-control form-absence-descr"
                                    id="reason"
                                    name="reason"
                                    maxLength={255}
                                    placeholder={translate('pages.absences.descriptionExample')}
                                    defaultValue=""
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>{translate('common.note')}</Label>
                                <Input
                                    type="textarea"
                                    rows={4}
                                    className="form-control form-absence-comment"
                                    id="comment"
                                    name="comment"
                                    placeholder={translate('pages.absences.remarksAboutLeaveOfAbsenceRequest')}
                                    defaultValue=""
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="link" id="modal-close" onClick={onCancel}>
                        {translate('common.cancel')}
                    </Button>
                    <Button type="submit" color="orange" disabled={!canSubmit}>{translate('common.save')}</Button>
                </ModalFooter>
            </form>
        );
    }
}

AddLeaveOfAbsenceForm.propTypes = {
    employees: PropTypes.array,
    pacoPayrollPeriods: PropTypes.array.isRequired,
    payrollPeriods: PropTypes.array.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default AddLeaveOfAbsenceForm;
