import { authorizedFetch } from '../../helpers/authorizedFetch';
import { formatDate } from '../../helpers/date';
import trans from '../../helpers/trans';
import { generateApiUrl, generateApiV3Url } from '../../helpers/url';
import getIncluded from '../../japi/getIncluded';
import getMultipleIncluded from '../../japi/getMultipleIncluded';
import isResourceCollectionDocument from '../../japi/guards/isResourceCollectionDocument';
import isResourceDocument from '../../japi/guards/isResourceDocument';
import { JapiDocument } from '../../japi/types/Document';
import { FetchResourceError } from '../../japi/types/FetchResourceError';
import { DateFormat } from '../../types/dateFormatTypes';
import { PaginationRequestApiParams } from '../../types/paginations';
import { RoleType } from '../../types/roleTypes';
import { UserStatus } from '../../types/userStatus';
import { AddressResource } from '../Address/Address';
import { transformToAddress } from '../Address/AddressTransformers';
import { ContractHoursResource } from '../ContractHours/ContractHours';
import { alignContractHours } from '../ContractHours/ContractHoursHelpers';
import { transformToContractHours } from '../ContractHours/ContractHoursTransformers';
import { DashboardSetting, DashboardSettingResource } from '../DashboardSetting/DashboardSetting';
import { transformDashboardSettingIdsToEditDashboardRequest, transformResourceToDashboardSetting } from '../DashboardSetting/DashboardSettingTransformers';
import { DepartmentResource } from '../Department/Department';
import { transformToDepartment } from '../Department/DepartmentTransformers';
import { DepartmentGroupResource } from '../DepartmentGroup/DepartmentGroup';
import { transformToDepartmentGroup } from '../DepartmentGroup/DepartmentGroupTransformers';
import { DivergentPostEmploymentHourResource } from '../DivergentPostEmploymentHour/DivergentPostEmploymentHour';
import { transformToDivergentPostEmploymentHour } from '../DivergentPostEmploymentHour/DivergentPostEmploymentHourTransformers';
import { DivergentPreEmploymentHourResource } from '../DivergentPreEmploymentHour/DivergentPreEmploymentHour';
import { transformToDivergentPreEmploymentHour } from '../DivergentPreEmploymentHour/DivergentPreEmploymentHourTransformers';
import { EmergencyContactResource } from '../EmergencyContact/EmergencyContact';
import { transformToEmergencyContact } from '../EmergencyContact/EmergencyContactTransformers';
import { EmploymentResource } from '../Employment/Employment';
import { transformToEmployment } from '../Employment/EmploymentTransformers';
import { EmploymentTypeResource } from '../EmploymentType/EmploymentType';
import { transformToEmploymentType } from '../EmploymentType/EmploymentTypeTransformers';
import { ExperienceResource } from '../Experience/Experience';
import { transformToExperience } from '../Experience/ExperienceTransformers';
import { FetchCollectionResult, FetchResult, FetchResultType } from '../FetchResult';
import { Period } from '../Period/Period';
import { Permission, PermissionResource } from '../Permission/Permission';
import { transformPermissionIdsToEditPermissionRequest, transformToPermission } from '../Permission/PermissionTransformers';
import { PersonResource } from '../Person/Person';
import { transformToPerson } from '../Person/PersonTransformers';
import { RoleResource } from '../Role/Role';
import { transformToRole } from '../Role/RoleTransformers';
import {
    AddUserFormData,
    BasicUser,
    BasicUserFilters,
    BasicUserResource,
    EditUserFormData,
    FullUser,
    User,
    UserContractHours,
    UserProfile,
    UserProfileV3Resource,
    UserResource,
    UserWithEmployment,
    UserWithHourBalances,
    WorkweekUser,
    WorkweekUserResource,
} from './User';
import {
    transformAddUserFormDataToAddApiParams,
    transformBasicUserResourceToBasicV3User,
    transformEditUserFormDataToEditApiParams,
    transformEditUserStatusRequest,
    transformToFullUser,
    transformToUser,
    transformToUserWithEmployment,
    transformToUserWithHourBalances,
    transformToWorkweekUser,
    transformUserProfileV3,
} from './UserTransformers';

export const patchUserDashboardSettingsCall = async (dashboardSettingIds: string[], userId: string): Promise<FetchResult<DashboardSetting[], string>> => {
    const editDashboardRequest = transformDashboardSettingIdsToEditDashboardRequest(dashboardSettingIds, userId);

    try {
        const url = generateApiUrl({
            endpoint: '/me/user',
            queryParams: {
                include: 'dashboardSettings',
            },
        });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
            body: JSON.stringify({ data: editDashboardRequest }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<UserResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const dashboardSettingsResource = getMultipleIncluded(doc, doc.data, 'dashboardSettings') as DashboardSettingResource[];
        const dashboardSettings = dashboardSettingsResource.map(transformResourceToDashboardSetting);

        return {
            data: dashboardSettings,
            status: response.status,
            type: FetchResultType.Success,
        };
    } catch (error) {
        console.error('[patchUserDashboardSettingsCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const postUserApiCall = async (userFormData: AddUserFormData): Promise<FetchResult<User, string>> => {
    const apiData = transformAddUserFormDataToAddApiParams(userFormData);

    try {
        const includes = [
            'employments',
            'employments.divergentPostEmploymentHours',
            'employments.divergentPreEmploymentHours',
        ];

        const url = generateApiUrl({
            endpoint: '/users',
            queryParams: {
                include: includes.join(','),
            },
        });

        const response = await authorizedFetch(url, {
            method: 'POST',
            body: JSON.stringify({ data: apiData }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<UserResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: doc.errors[0]?.detail || trans('errors.unknownError'),
            };
        }

        const employmentsResource = getMultipleIncluded(doc, doc.data, 'employments') as EmploymentResource[];
        const employments = employmentsResource.map(employment => {
            const divergentPostEmploymentHourResource = getIncluded(doc, employment, 'divergentPostEmploymentHours') as DivergentPostEmploymentHourResource;
            const divergentPostEmploymentHour = divergentPostEmploymentHourResource ? transformToDivergentPostEmploymentHour(divergentPostEmploymentHourResource) : undefined;
            const divergentPreEmploymentHourResource = getIncluded(doc, employment, 'divergentPreEmploymentHours') as DivergentPreEmploymentHourResource;
            const divergentPreEmploymentHour = divergentPreEmploymentHourResource ? transformToDivergentPreEmploymentHour(divergentPreEmploymentHourResource) : undefined;

            return transformToEmployment(employment, divergentPostEmploymentHour, divergentPreEmploymentHour);
        });
        const user = transformToUser(doc.data, employments[employments.length - 1]);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: user,
        };
    } catch (error) {
        console.error('[postUserApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const patchUserApiCall = async (
    editUserFormData: EditUserFormData,
): Promise<FetchResult<User, string>> => {
    const apiData = transformEditUserFormDataToEditApiParams(editUserFormData);

    try {
        const includes = [
            'employments',
            'employments.divergentPostEmploymentHours',
            'employments.divergentPreEmploymentHours',
        ];

        const url = generateApiUrl({
            endpoint: `/users/${apiData.id}`,
            queryParams: {
                include: includes.join(','),
            },
        });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
            body: JSON.stringify({ data: apiData }),
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<UserResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: doc.errors[0]?.detail || trans('errors.unknownError'),
            };
        }

        const employmentsResource = getMultipleIncluded(doc, doc.data, 'employments') as EmploymentResource[];
        const employments = employmentsResource.map(employment => {
            const divergentPostEmploymentHourResource = getIncluded(doc, employment, 'divergentPostEmploymentHours') as DivergentPostEmploymentHourResource;
            const divergentPostEmploymentHour = divergentPostEmploymentHourResource ? transformToDivergentPostEmploymentHour(divergentPostEmploymentHourResource) : undefined;
            const divergentPreEmploymentHourResource = getIncluded(doc, employment, 'divergentPreEmploymentHours') as DivergentPreEmploymentHourResource;
            const divergentPreEmploymentHour = divergentPreEmploymentHourResource ? transformToDivergentPreEmploymentHour(divergentPreEmploymentHourResource) : undefined;

            return transformToEmployment(employment, divergentPostEmploymentHour, divergentPreEmploymentHour);
        });

        const user = transformToUser(doc.data, employments[employments.length - 1]);

        return {
            status: 200,
            type: FetchResultType.Success,
            data: user,
        };
    } catch (error) {
        console.error('[patchUserApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const removeUserApiCall = async (userId: string): Promise<FetchResult<undefined, string>> => {
    const apiData = transformEditUserStatusRequest(userId, UserStatus.deleted);

    try {
        const url = generateApiUrl({ endpoint: `/users/${userId}` });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
            body: JSON.stringify({ data: apiData }),
        });

        if (!response.ok) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: undefined,
        };
    } catch (error) {
        console.error('[removeUserApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

let getBasicUsersApiCallAbortController = new AbortController();

export const getBasicUsersApiCall = async (userFilters: BasicUserFilters): Promise<FetchResult<BasicUser[], string>> => {
    const {
        userSearch = '',
        departments = [],
        employmentTypes = [],
        roles = [],
        onlyShowMainDepartment = false,
    } = userFilters;

    getBasicUsersApiCallAbortController.abort();
    getBasicUsersApiCallAbortController = new AbortController();

    try {
        const url = generateApiV3Url({
            endpoint: '/users',
            queryParams: {
                search: userSearch,
                ...(departments.length > 0 && { [onlyShowMainDepartment ? 'mainDepartments' : 'departments']: JSON.stringify(departments) }),
                ...(employmentTypes.length > 0 && { employmentTypes: JSON.stringify(employmentTypes) }),
                ...(roles.length > 0 && { roles: JSON.stringify(roles) }),
            },
        });

        const response = await authorizedFetch(url, { signal: getBasicUsersApiCallAbortController.signal });

        const doc: BasicUserResource[] = await response.json();

        if (!doc) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const users = doc.map(transformBasicUserResourceToBasicV3User);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: users,
        };
    } catch (error) {
        console.error('[getBasicUsersApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getFullUserApiCall = async (userId: string): Promise<FetchResult<FullUser, string>> => {
    try {
        const includes = [
            'departments',
            'departments.group',
            'departmentGroups',
            'employments',
            'employments.divergentPostEmploymentHours',
            'employments.divergentPreEmploymentHours',
            'employmentType',
            'mainDepartment',
            'mainDepartment.group',
            'periodicalContractHours',
            'person.experience',
            'person.emergencyContact',
            'person.addresses',
            'roles',
        ];

        const url = generateApiUrl({
            endpoint: `/users/${userId}`,
            queryParams: {
                include: includes.join(','),
            },
        });

        const response = await authorizedFetch(url);

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<UserResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const personResource = getIncluded(doc, doc.data, 'person') as PersonResource;
        const mainDepartmentResource = getIncluded(doc, doc.data, 'mainDepartment') as DepartmentResource;
        const mainDepartmentGroupResource = mainDepartmentResource ? getIncluded(doc, mainDepartmentResource, 'group') as DepartmentGroupResource : undefined;
        const departmentsResource = getMultipleIncluded(doc, doc.data, 'departments') as DepartmentResource[];
        const departmentGroupsResource = getMultipleIncluded(doc, doc.data, 'departmentGroups') as DepartmentGroupResource[];
        const rolesResource = getMultipleIncluded(doc, doc.data, 'roles') as RoleResource[];
        const employmentTypeResource = getIncluded(doc, doc.data, 'employmentType') as EmploymentTypeResource;
        const employmentsResource = getMultipleIncluded(doc, doc.data, 'employments') as EmploymentResource[];
        const experienceResource = getIncluded(doc, personResource, 'experience') as ExperienceResource;
        const addressesResource = getMultipleIncluded(doc, personResource, 'addresses') as AddressResource[];
        const contractHoursResource = getMultipleIncluded(doc, doc.data, 'periodicalContractHours') as ContractHoursResource[];
        const emergencyContactResource = getIncluded(doc, personResource, 'emergencyContact') as EmergencyContactResource;

        const person = transformToPerson(personResource);
        const mainDepartment = mainDepartmentResource ? transformToDepartment(mainDepartmentResource, mainDepartmentGroupResource) : undefined;
        const departments = departmentsResource.map(departmentResource => {
            const departmentGroupResource = getIncluded(doc, departmentResource, 'group') as DepartmentGroupResource;

            return transformToDepartment(departmentResource, departmentGroupResource);
        });
        const departmentGroups = departmentGroupsResource.map(transformToDepartmentGroup);
        const roles = rolesResource.map(transformToRole);
        const employmentType = transformToEmploymentType(employmentTypeResource);
        const experience = experienceResource ? transformToExperience(experienceResource) : undefined;
        const addresses = addressesResource.map(transformToAddress);
        const emergencyContact = emergencyContactResource ? transformToEmergencyContact(emergencyContactResource) : undefined;
        const contractHours = alignContractHours(contractHoursResource.map(transformToContractHours));

        const employments = employmentsResource.map(employment => {
            const divergentPostEmploymentHourResource = getIncluded(doc, employment, 'divergentPostEmploymentHours') as DivergentPostEmploymentHourResource;
            const divergentPostEmploymentHour = divergentPostEmploymentHourResource ? transformToDivergentPostEmploymentHour(divergentPostEmploymentHourResource) : undefined;
            const divergentPreEmploymentHourResource = getIncluded(doc, employment, 'divergentPreEmploymentHours') as DivergentPreEmploymentHourResource;
            const divergentPreEmploymentHour = divergentPreEmploymentHourResource ? transformToDivergentPreEmploymentHour(divergentPreEmploymentHourResource) : undefined;

            return transformToEmployment(employment, divergentPostEmploymentHour, divergentPreEmploymentHour);
        });

        const user = transformToUser(doc.data, employments[employments.length - 1]);

        const fullUser = transformToFullUser(
            user,
            contractHours,
            departments,
            departmentGroups,
            employmentType,
            roles[roles.length - 1],
            person,
            mainDepartment,
            addresses[addresses.length - 1],
            emergencyContact,
            experience,
        );

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: fullUser,
        };
    } catch (error) {
        console.error('[getFullUserApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

interface getUsersApiCallProps {
    departments?: string[],
    showInactiveUsers?: boolean;
    userSearch?: string;
}

export const getUsersWithEmploymentApiCall = async ({ departments = [], userSearch = '', showInactiveUsers }: getUsersApiCallProps): Promise<FetchResult<UserWithEmployment[], string>> => {
    try {
        const includes = [
            'employments',
            'employments.divergentPostEmploymentHours',
            'employments.divergentPreEmploymentHours',
        ];

        const url = generateApiUrl({
            endpoint: '/users',
            queryParams: {
                include: includes.join(','),
                search: userSearch,
                ...(departments.length) && { departments: JSON.stringify(departments) },
                ...(showInactiveUsers !== undefined && { show_inactive_users: showInactiveUsers }),
            },
        });

        const response = await authorizedFetch(url);

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<UserResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const users = doc.data.map(user => {
            const employmentsResource = getMultipleIncluded(doc, user, 'employments') as EmploymentResource[];
            const employments = employmentsResource.map(employment => {
                const divergentPostEmploymentHourResource = getIncluded(doc, employment, 'divergentPostEmploymentHours') as DivergentPostEmploymentHourResource;
                const divergentPostEmploymentHour = divergentPostEmploymentHourResource ? transformToDivergentPostEmploymentHour(divergentPostEmploymentHourResource) : undefined;
                const divergentPreEmploymentHourResource = getIncluded(doc, employment, 'divergentPreEmploymentHours') as DivergentPreEmploymentHourResource;
                const divergentPreEmploymentHour = divergentPreEmploymentHourResource ? transformToDivergentPreEmploymentHour(divergentPreEmploymentHourResource) : undefined;

                return transformToEmployment(employment, divergentPostEmploymentHour, divergentPreEmploymentHour);
            });

            return transformToUserWithEmployment(user, employments[employments.length - 1]);
        });

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: users,
        };
    } catch (error) {
        console.error('[getUsersWithEmploymentApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

interface getUsersWithHourBalancesApiCallProps {
    isWorkweek?: boolean;
    onlyShowMainDepartment?: boolean;
    showInactiveUsers?: boolean;
    departments?: string[];
    employmentPeriod?: Period;
    employmentTypes?: string[];
    pagination: PaginationRequestApiParams;
    roles?: string[];
    userSearch?: string;
}

export const getUsersWithHourBalancesApiCall = async ({
    onlyShowMainDepartment = false,
    showInactiveUsers = false,
    departments: filterDepartments = [],
    employmentPeriod,
    employmentTypes = [],
    pagination,
    roles = [],
    userSearch = '',
}: getUsersWithHourBalancesApiCallProps): Promise<FetchCollectionResult<UserWithHourBalances[], string>> => {
    try {
        const includes = [
            'departments',
            'departments.group',
            'employments',
            'employments.divergentPostEmploymentHours',
            'employments.divergentPreEmploymentHours',
            'employmentType',
            'mainDepartment',
            'periodicalContractHours',
        ];

        const url = generateApiUrl({
            endpoint: '/users',
            queryParams: {
                orderBy: JSON.stringify({ 'person.firstname': 'ASC' }),
                ...(employmentTypes.length && { employmentTypes: JSON.stringify(employmentTypes) }),
                include: includes.join(','),
                ...((!onlyShowMainDepartment && filterDepartments.length) && { departments: JSON.stringify(filterDepartments) }),
                ...((onlyShowMainDepartment && filterDepartments.length) && { mainDepartments: JSON.stringify(filterDepartments) }),
                ...(pagination && { 'page[number]': pagination.number }),
                ...(employmentPeriod?.start && { employmentStart: formatDate(employmentPeriod.start, DateFormat.apiDate) }),
                ...(employmentPeriod?.end && { employmentEnd: formatDate(employmentPeriod.end, DateFormat.apiDate) }),
                ...(roles.length && { roles: JSON.stringify(roles) }),
                search: userSearch,
                show_inactive_users: showInactiveUsers ? 1 : 0,
                withPriorityOnRole: true,
            },
            ...(pagination && { pageSize: pagination.size }),
        });

        const response = await authorizedFetch(url);

        const doc: JapiDocument = await response.json();

        if (!isResourceCollectionDocument<UserResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const users = doc.data.map(userResource => {
            const employmentTypeResource = getIncluded(doc, userResource, 'employmentType') as EmploymentTypeResource;
            const departmentsResource = getMultipleIncluded(doc, userResource, 'departments') as DepartmentResource[];
            const mainDepartmentResource = getIncluded(doc, userResource, 'mainDepartment') as DepartmentResource;
            const employmentsResource = getMultipleIncluded(doc, userResource, 'employments') as EmploymentResource[];
            const employments = employmentsResource.map(employment => {
                const divergentPostEmploymentHourResource = getIncluded(doc, employment, 'divergentPostEmploymentHours') as DivergentPostEmploymentHourResource;
                const divergentPostEmploymentHour = divergentPostEmploymentHourResource ? transformToDivergentPostEmploymentHour(divergentPostEmploymentHourResource) : undefined;
                const divergentPreEmploymentHourResource = getIncluded(doc, employment, 'divergentPreEmploymentHours') as DivergentPreEmploymentHourResource;
                const divergentPreEmploymentHour = divergentPreEmploymentHourResource ? transformToDivergentPreEmploymentHour(divergentPreEmploymentHourResource) : undefined;

                return transformToEmployment(employment, divergentPostEmploymentHour, divergentPreEmploymentHour);
            });
            const contractHoursResource = getMultipleIncluded(doc, userResource, 'periodicalContractHours') as ContractHoursResource[];
            const contractHours = contractHoursResource.map(transformToContractHours);
            const user = transformToUser(userResource, employments[employments.length - 1]);

            const employmentType = transformToEmploymentType(employmentTypeResource);
            const departments = departmentsResource.map(departmentResource => {
                const departmentGroupResource = getIncluded(doc, departmentResource, 'group') as DepartmentGroupResource;

                return transformToDepartment(departmentResource, departmentGroupResource);
            });
            const mainDepartment = mainDepartmentResource ? transformToDepartment(mainDepartmentResource) : undefined;

            return transformToUserWithHourBalances(
                user,
                contractHours,
                departments,
                employments[employments.length - 1],
                employmentType,
                mainDepartment,
            );
        });

        return {
            amountOfPages: doc.meta?.pagination.pages || 1,
            status: response.status,
            type: FetchResultType.Success,
            data: users,
        };
    } catch (error) {
        console.error('[getUsersWithHourBalancesApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getUserContractHoursApiCallV3 = async (userId: string, startDate: string, endDate: string): Promise<FetchResult<number, string>> => {
    try {
        const url = generateApiV3Url({
            endpoint: `/users/periodical-contract-hours/${userId}/${startDate}/${endDate}`,
            queryParams: {
                start: formatDate(new Date(startDate), DateFormat.apiDateTime),
                end: formatDate(new Date(endDate), DateFormat.apiDateTime),
            },
        });

        const response = await authorizedFetch(url);

        const doc = await response.json();
        const data = doc as UserContractHours;

        return {
            data: data.contractHours,
            status: response.status,
            type: FetchResultType.Success,
        };
    } catch (error) {
        console.error('[getUsersAbsenseHoursApiCallV3]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const postUserResendRegistrationApiCall = async (userId: string): Promise<FetchResult<string, string>> => {
    try {
        const url = generateApiUrl({
            endpoint: `/users/${userId}/resend-registration-mail`,
        });

        const response = await authorizedFetch(url, { method: 'POST' });

        if (!response.ok) {
            const doc = await response.json();
            const errorResponse = doc as FetchResourceError;
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: errorResponse.errors[0].detail || trans('errors.unknownError'),
            };
        }

        return {
            data: await response.text(),
            status: response.status,
            type: FetchResultType.Success,
        };
    } catch (error) {
        console.error('[postUserResendRegistrationApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const getUserProfileV3ApiCall = async (userId?: string): Promise<FetchResult<UserProfile, string>> => {
    try {
        const url = generateApiV3Url({
            endpoint: `/users/profile/${userId || 'me'}`,
        });

        const response = await authorizedFetch(url);

        const doc = await response.json();

        if (!doc) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const data = doc as UserProfileV3Resource;
        const userProfile = transformUserProfileV3(data);

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: userProfile,
        };
    } catch (error) {
        console.error('[getUserProfileV3ApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export interface GetWorkweekUsersApiCallProps {
    onlyShowMainDepartment?: boolean;
    workweek: Date;
    departments?: string[];
    employmentTypes?: string[];
    roles?: RoleType[];
    search?: string;
}

export const getWorkweekUsersApiCall = async (filter: GetWorkweekUsersApiCallProps): Promise<FetchCollectionResult<WorkweekUser[], string>> => {
    try {
        const {
            onlyShowMainDepartment,
            search = '',
            departments = [],
            employmentTypes = [],
            roles = [
                RoleType.admin,
                RoleType.headManager,
                RoleType.employee,
                RoleType.establishmentManager,
                RoleType.manager,
                RoleType.humanResources,
                RoleType.juniorManager,
            ],
        } = filter;
        const url = generateApiV3Url({
            endpoint: `/users/workweek/${formatDate(filter.workweek, DateFormat.apiDateTime)}`,
            queryParams: {
                search,
                ...(departments.length > 0 && { [onlyShowMainDepartment ? 'mainDepartments' : 'departments']: JSON.stringify(departments) }),
                ...(employmentTypes.length > 0 && { employmentTypes: JSON.stringify(employmentTypes) }),
                ...(roles.length > 0 && { roles: JSON.stringify(roles) }),
            },
        });

        const response = await authorizedFetch(url);

        const doc = await response.json();

        if (!doc) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const data = doc as WorkweekUserResource[];
        const users = data.map(transformToWorkweekUser);

        return {
            amountOfPages: 1,
            status: response.status,
            type: FetchResultType.Success,
            data: users,
        };
    } catch (error) {
        console.error('[getWorkweekUsersApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const resetUserPasswordApiCall = async (userId: string): Promise<FetchResult<boolean, string>> => {
    try {
        const url = generateApiUrl({
            endpoint: `/users/${userId}/forgot-password`,
        });

        const response = await authorizedFetch(url, { method: 'POST' });

        if (!response) {
            return {
                status: 500,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: true,
        };
    } catch (error) {
        console.error('[resetUserPasswordApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const setUserStatusApiCall = async (userId: string, status: UserStatus): Promise<FetchResult<boolean, string>> => {
    const apiData = transformEditUserStatusRequest(userId, status);

    try {
        const url = generateApiUrl({
            endpoint: `/users/${userId}`,
        });

        const response = await authorizedFetch(url, {
            body: JSON.stringify({ data: apiData }),
            method: 'PATCH',
        });

        const doc: JapiDocument = await response.json();

        if (!doc.data) {
            return {
                status: 500,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: true,
        };
    } catch (error) {
        console.error('[setUserStatusApiCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

let patchUserPermissionsApiCallAbortController = new AbortController();

export const patchUserPermissionsApiCall = async (permissionIds: string[], userId: string): Promise<FetchResult<Permission[], string>> => {
    const editPermissionsRequest = transformPermissionIdsToEditPermissionRequest(permissionIds, userId);

    try {
        patchUserPermissionsApiCallAbortController.abort();
        patchUserPermissionsApiCallAbortController = new AbortController();

        const url = generateApiUrl({
            endpoint: `/users/${userId}`,
            queryParams: {
                include: 'permissions',
            },
        });

        const response = await authorizedFetch(url, {
            method: 'PATCH',
            body: JSON.stringify({ data: editPermissionsRequest }),
            signal: patchUserPermissionsApiCallAbortController.signal,
        });

        const doc: JapiDocument = await response.json();

        if (!isResourceDocument<UserResource>(doc) || !doc.data) {
            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        const permissionsResource = getMultipleIncluded(doc, doc.data, 'permissions') as PermissionResource[];
        const permissions = permissionsResource.map(transformToPermission);

        return {
            data: permissions,
            status: response.status,
            type: FetchResultType.Success,
        };
    } catch (error) {
        console.error('[patchUserPermissionsCall]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
