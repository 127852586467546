import { SpecialDay, SpecialDayResource } from './SpecialDay';

export const transformToSpecialDay = (
    specialDayResource: SpecialDayResource,
): SpecialDay => ({
    id: specialDayResource.id,
    holidayAllowance: specialDayResource.attributes.holidayAllowance,
    isVisibleInEmployeeApp: specialDayResource.attributes.isVisibleInEmployeeApp,
    type: specialDayResource.attributes.type,
    description: specialDayResource.attributes.description,
    date: new Date(specialDayResource.attributes.date),
});
