import { startOfDayInHours } from '../../@paco/helpers/date';
import { addDays } from '../../helpers/date';
import UpToAndIncludingDate from '../../helpers/date/UpToAndIncludingDate';
import getEndOfWeek from './getEndOfWeek';


/**
 * Returns the first day of the ISO week (Monday) of a given year.
 * ISO 8601 considers the first week of the year to be the one containing at least 4 days of the new year.
 */
function getFirstWeekdayOfYear(year: number): Date {
    const firstDayOfYear = new Date(year, 0, 1); // January 1st
    const dayOfWeek = firstDayOfYear.getDay() || 7; // Sunday (0) is treated as 7
    const firstWeekday = addDays(firstDayOfYear, dayOfWeek <= 4 ? 1 - dayOfWeek : 8 - dayOfWeek); // Adjust to the first Monday
    firstWeekday.setHours(startOfDayInHours);
    return firstWeekday;
}

export function getDatesFromWeekNumber(
    year: number,
    weekNumber: number,
): { start: Date, end: UpToAndIncludingDate } {
    const startOfFirstWeekdayOfYear = getFirstWeekdayOfYear(year);
    const startOfWeek = addDays(startOfFirstWeekdayOfYear, (weekNumber - 1) * 7);
    const endOfWeek = getEndOfWeek(startOfWeek, startOfDayInHours);

    return {
        start: startOfWeek,
        end: endOfWeek,
    };
}
