import { SpecialDayResource, SpecialDayViewModel } from '../../models/SpecialDay';

export default function transformSpecialDayResource(resource: SpecialDayResource): SpecialDayViewModel {
    return {
        id: resource.id,
        type: resource.type,
        date: new Date(resource.date),
        description: resource.description,
        holidayAllowance: resource.holidayAllowance,
        isVisibleInEmployeeApp: resource.isVisibleInEmployeeApp,
    };
}
